<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitData">
          <v-card-title></v-card-title>
          <v-card-text>
            <p></p>
            <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
              <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
            </div>
            <v-row wrap>
              <v-col align="start" cols="12" md="12">
                <div class="notes-content">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  @click.prevent="refreshPageData"
                  color="#a4c639"
                >Refresh</v-btn>
                <br />
              </v-col>
            </v-row>
            <v-container></v-container>
            <v-container>
              <v-row wrap>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6>App Name</h6>
                  <h4>{{ AppName }}</h4>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6>Settings Name</h6>
                  <h4>{{ SettingsName }}</h4>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      Title
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field
                    v-model="Title"
                    :rules="TitleRules"
                    placeholder="Title"
                    hint="Enter image title"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      Description
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field
                    v-model="Description"
                    :rules="DescriptionRules"
                    placeholder="Description"
                    hint="Enter image description"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap v-if="InputType == 2">
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6>Exist Image</h6>
                  <img v-if="OldImagePath != ''" width="100" height="100" :src="OldImagePath" />
                </v-col>
                <v-col align="center" cols="12" sm="6" lg="4" md="4">
                  <h6>
                    Upload Image
                    <small>(optional)</small>
                  </h6>
                  <v-file-input
                    :clearable="false"
                    truncate-length="25"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Upload Event Photo"
                    prepend-icon="mdi-account-group"
                    append-outer-icon="mdi-delete"
                    v-model="UploadImage1"
                    @change="addImage(1)"
                    @click:append-outer="deleteImage(1)"
                  ></v-file-input>
                  <div class="actions">
                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="flip(true, false)">
                        <v-icon>fa fa-caret-right</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="flip(false, true)">
                        <v-icon>fa fa-caret-up</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="rotate(90)">
                        <v-icon>fa fa-redo</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="rotate(-90)">
                        <v-icon>fa fa-undo</v-icon>
                      </v-btn>
                    </b-button-group>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h4>Uploaded image will appear here</h4>
                  <cropper
                    ref="cropper"
                    :src="SettingsImage.ImageUrl"
                    :stencil-props="{
                        handlers: {},
                        movable: false,
                        scalable: false,
                      }"
                    :stencil-size="StencilSize"
                    image-restriction="stencil"
                  />
                </v-col>
              </v-row>
              <v-row wrap v-if="InputType == 3">
                <v-col cols="12" md="12">
                  <tiptap-vuetify v-model="Content" :extensions="extensions" />
                </v-col>
              </v-row>
              <!-- <div
                  id="editor1"
                  ref="editor1"
              ></div>-->
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#a52a2a"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Close</v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="getCroppedImage"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  mixins: [common],
  components: { TiptapVuetify },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    editRecords: {
      required: true
    },
    PageTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      valid1: true,

      extensions: [
        History,
        Blockquote,
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        HorizontalRule,
        Paragraph,
        HardBreak
      ],

      SettingsValuesId: "",
      AppName: "",
      SettingsName: "",
      OldImagePath: "",

      LoadingFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      AppTypeRules: [v => !!v || "App Type is required"],
      AppType: "",
      AppTypeOptions: [],
      AppTypeOptionsLoading: false,

      SettingsTypeRules: [v => !!v || "Settings Type is required"],
      SettingsType: "",
      SettingsTypeOptions: [],
      SettingsTypeOptionsLoading: false,

      Title: "",
      TitleRules: [],

      Description: "",
      DescriptionRules: [],

      Content: "",
      ContentRules: [],

      InputType: "",

      Settings: {},
      UploadImage1: {},
      SettingsImage: {},
      StencilSize: {},

      image: {
        src: "",
        type: null
      },

      current_photo: "",

      rowData: {
        PopupId: 1
      },

      PageInfo: {},
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: ""
    };
  },
  computed: {},
  watch: {
    SettingsTypeOptions: function() {
      console.log("watch SettingsTypeOptions");
      this.Settings = {};
      this.StencilSize = { width: 250, height: 250 };
      var temp_code = this.SettingsType;
      console.log("temp_code=" + temp_code);
      var idx = this.SettingsTypeOptions.map(e => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var tr = this.SettingsTypeOptions[idx];
        console.log({ tr });
        this.Settings = tr;
        this.StencilSize = {
          width: tr.image_width,
          height: tr.image_height
        };
      }
    },
  },
  created() {
    // this.initialize()
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var SettingsValuesId = this.editRecords.SettingsValuesId;
      var AppName = this.editRecords.AppName;
      var SettingsName = this.editRecords.SettingsName;

      if (SettingsValuesId > 0 && SettingsValuesId != "") {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/app-settings-values/show";
        var upload = {
          UserInterface: 1,
          SettingsValuesId: SettingsValuesId,
          AppName: AppName,
          SettingsName: SettingsName
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            console.log({ records });

            if (flag == 1) {
              thisIns.SettingsValuesId = records.SettingsValuesId;
              thisIns.Title = records.SettingsValuesTitle;
              thisIns.Description = records.SettingsValuesDescription;
              thisIns.Content = records.SettingsValuesContent;
              thisIns.OldImagePath = records.SettingsValuesImg;
              thisIns.SettingsType = records.SettingsId;
              thisIns.AppType = records.AppId;
              thisIns.AppName = records.AppDisplayName;
              thisIns.SettingsName = records.SettingsDisplayName;
              thisIns.InputType = records.SettingsInputType;
              thisIns.getSettingsTypeOptions();

              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      console.log(this.ResultFlag);
      this.$emit("hideDialog",this.ResultFlag);
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      if (this.$refs.cropper.customImageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SettingsImage.src) {
            URL.revokeObjectURL(this.SettingsImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SettingsImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file
          };
          break;

        default:
          break;
      }
    },
    getSettingsTypeOptions() {
      console.log("getSettingsTypeOptions called");
      this.SettingsTypeOptionsLoading = true;
      var selectbox1_source = "SettingsType";
      var selectbox1_destination = "SettingsTypeOptions";
      var selectbox1_url = "api/app-settings/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        AppType: this.AppType
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SettingsImage = {
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null
          };
          break;

        default:
          break;
      }
    },
    getCroppedImage() {
      console.log("getCroppedImage called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;

      if (validate1) {
        var InputType = this.InputType;
        console.log({ InputType });

        var Title = this.Title == null ? "" : this.Title;
        var Description = this.Description == null ? "" : this.Description;
        var Content = this.Content == null ? "" : this.Content;

        let upload = new FormData();
        upload.append("UserInterface", InputType);

        var form = {
          SettingsValuesId: this.SettingsValuesId,
          AppType: this.AppType,
          SettingsType: this.SettingsType,
          Title: Title,
          Description: Description,
          Content: Content
        };
        upload.append("Form", JSON.stringify(form));

        var file = this.UploadImage1;
        var FileName = file.name;
        console.log({ FileName });
        var FileType = file.type;
        console.log({ FileType });

        if (InputType == 2) {
          if (FileName != undefined && FileName != "") {
            const { canvas } = this.$refs.cropper.getResult();
            if (canvas) {
              canvas.toBlob(blob => {
                upload.append("SettingsImage", blob, FileName);
                this.submitForm(upload);
              }, FileType);
            }
          } else {
            if (this.OldImagePath == "") {
              var message = "Kindly fill the required fields";
              this.sweetAlert("error", message, false);
            }
            upload.append("SettingsImage", null);
            this.submitForm(upload);
          }
        } else if (
          InputType == 3 &&
          this.Content == "" &&
          this.Content == null
        ) {
          var message = "Kindly fill the required fields";
          this.sweetAlert("error", message, false);
        } else {
          upload.append("SettingsImage", null);
          this.submitForm(upload);
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", output, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;

      if (validate1) {
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/app-settings-values/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.sweetAlert("success", output, true);
              // thisIns.ShowFormFlag = false;
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        // this.toast("error", message);
        this.sweetAlert("error", output, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>